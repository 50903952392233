import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseConfig'; // Import Firebase auth and db
import { ref, get } from 'firebase/database'; // Import Firebase Realtime Database functions
import backgroundImage from './homepageBackground.jpg'; // Import the background image

function CategoryPage() {
  const { category } = useParams(); // Get the category from the URL
  const navigate = useNavigate(); // For redirecting users
  const [accessDenied, setAccessDenied] = useState(false); // State to track access status
  const [loading, setLoading] = useState(true); // State to manage loading status

  // Disable right-click functionality
  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();
    document.addEventListener('contextmenu', disableRightClick);
    
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  // Hardcoded file links for each category
  const categoryFiles = {
    stab52: {
      pdfs: [
        {name:'STAB52_公开课_学生_new', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/STAB52_%E5%85%AC%E5%BC%80%E8%AF%BE_%E5%AD%A6%E7%94%9F_new.pdf'},
        { name: 'STAB52_B53_Class1_额外习题_学生', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/STAB52_B53_Class1_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%98_%E5%AD%A6%E7%94%9F.pdf' },
        //{ name: 'Lecture 2 Notes', url: 'https://example.com/stab52_lecture2.pdf' },
      ],
      videos: [
        { name: 'Week 1试听课', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/Week1%E8%AF%95%E5%90%AC%E8%AF%BE.mp4' },
        {name: "额外练习题1",url :'https://storage.googleapis.com/videos-courses-web/24F/stab52/stab52_class1_%E9%A2%9D%E5%A4%96%E7%BB%83%E4%B9%A0%E9%A2%981.mp4'},
      ],

    },
    // Add other categories here
    matb41:{
        pdfs: [
            {name:'MATB41 class1 公开课', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class1%20%E5%85%AC%E5%BC%80%E8%AF%BE.pdf'},
            {name: 'MATB41 class2', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class2.pdf'},
            {name:'MATB41 class2 practice problem with sol', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class2%20practice%20problem%20with%20sol.pdf'},
            {},
        ],
        videos: [
            { name: 'Week 1试听课', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week1%20%E8%AF%95%E5%90%AC%E8%AF%BE.mp4' },
            { name: 'Week 02 LECTURE 课程回放', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20LECTURE%20%E8%AF%BE%E7%A8%8B%E5%9B%9E%E6%94%BE.mp4'},
            {name: 'Week 02 习题课 Assignment1讲解更新', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20%E4%B9%A0%E9%A2%98%E8%AF%BE%20Assignment1%E8%AE%B2%E8%A7%A3%E6%9B%B4%E6%96%B0.mp4'},
            {},
        ],
    },
    actb40:{
        pdfs:[
            {name: 'ACTB40 Class1', url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40%20Class1.pdf'},
            {name: "Quiz 1", url : 'https://storage.googleapis.com/videos-courses-web/24F/actb40/quiz%201.pdf'},
            {name:'ACTB40 Class2', url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40%20Class2.pdf'},
            {},
        ],
        videos:[
            {name: "Week 1试听课", url :'https://storage.googleapis.com/videos-courses-web/24F/actb40/week1%E8%AF%95%E5%90%AC%E8%AF%BE.mp4'},
            {name: "Week 2 Quiz 1",url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40-Week%2002%20LECTURE%20quiz1.mp4'},
            {name:'Week 03 LECTURE 课程回放', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/Week%2003%20LECTURE%20%E8%AF%BE%E7%A8%8B%E5%9B%9E%E6%94%BE.mp4'},
            {},
        ],
    },
    matb44:{
        pdfs:[
            {name:'MATB44公开课_学生', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/MATB44%E5%85%AC%E5%BC%80%E8%AF%BE_%E5%AD%A6%E7%94%9F.pdf'},
            {},
        ],
        videos:[
            {name: "Week 1试听课", url: "https://storage.googleapis.com/videos-courses-web/24F/matb44/Week1%20%E8%AF%95%E5%90%AC%E8%AF%BE.mp4"},
        ],
    },
  };

  useEffect(() => {
    const checkAccess = () => {
      auth.onAuthStateChanged(user => {
        if (user) {
          const uid = user.uid;
          const categoryNumber = getCategoryNumber(category); 
          const categoryRef = ref(db, `users/${uid}/categories/Category${categoryNumber}`);

          get(categoryRef).then(snapshot => {
            if (snapshot.exists() && snapshot.val()) {
              setLoading(false); // User has access
            } else {
              setAccessDenied(true); // User doesn't have access
              setLoading(false);
            }
          }).catch(error => {
            console.error("Error checking access:", error);
            setAccessDenied(true);
            setLoading(false);
          });
        } else {
          setAccessDenied(true); // No user is logged in
          setLoading(false);
        }
      });
    };

    checkAccess();
  }, [category]);

  const getCategoryNumber = (categoryName) => {
    switch (categoryName.toLowerCase()) {
      case 'stab52': return 1;
      case 'matb41': return 2;
      case 'matb44': return 3;
      case 'actb40': return 4;
      case 'mgeb02': return 5;
      default: return 0;
    }
  };

  if (loading) {
    return <div style={loadingStyle}>Loading...</div>; 
  }

  if (accessDenied) {
    return (
      <div style={accessDeniedStyle}>
        <div style={accessDeniedBoxStyle}> {/* Transparent box for the Access Denied message */}
          <h1>Access Denied</h1>
          <p>You do not have access to this category.</p>
          <button onClick={() => navigate('/')} style={backButtonStyle}>Go back to Home</button>
        </div>
      </div>
    );
  }

  // Get the files for the current category
  const files = categoryFiles[category.toLowerCase()] || { pdfs: [], videos: [] };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Files for {category.toUpperCase()}</h1>

      <div style={transparentBoxStyle}>
        {/* Section for PDF Files */}
        <div style={sectionStyle}>
          <h2>PDF Files</h2>
          <ul style={fileListUlStyle}>
            {files.pdfs.length > 0 ? (
              files.pdfs.map((file, index) => (
                <li key={index} style={fileItemStyle}>
                  <a href={file.url} target="_blank" rel="noopener noreferrer" style={fileLinkStyle}>
                    {file.name}
                  </a>
                </li>
              ))
            ) : (
              <p style={noFilesTextStyle}>No PDF files available for this category.</p>
            )}
          </ul>
        </div>

        {/* Section for Videos */}
        <div style={sectionStyle}>
          <h2>Videos</h2>
          <div style={videoContainerStyle}>
            {files.videos.length > 0 ? (
              files.videos.map((file, index) => (
                <div key={index} style={videoItemStyle}>
                  <h3>{file.name}</h3>
                  <video controls controlsList="nodownload" width="600" style={videoStyle}>
                    <source src={file.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))
            ) : (
              <p style={noFilesTextStyle}>No videos available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// CSS Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

const titleStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: '20px',
  fontSize: '28px',
  fontWeight: 'bold',
  zIndex: 1,
  textAlign: 'center',
  width: '100%',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
};

const transparentBoxStyle = {
  flexGrow: 1,
  overflowY: 'scroll',
  width: '80%',
  maxWidth: '900px',
  padding: '20px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '15px',
  color: '#fff',
  marginTop: '20px',
  textAlign: 'center',
};

const sectionStyle = {
  marginBottom: '40px',
};

const fileListUlStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
};

const fileItemStyle = {
  margin: '10px 0',
  fontSize: '18px',
};

const fileLinkStyle = {
  textDecoration: 'none',
  color: '#00bfff',
  fontWeight: 'bold',
  transition: 'color 0.2s',
};

const noFilesTextStyle = {
  fontSize: '18px',
  color: '#fff',
};

const videoContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const videoItemStyle = {
  marginBottom: '30px',
  textAlign: 'center',
};

const videoStyle = {
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  fontSize: '24px',
  color: '#333',
};

const accessDeniedStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark transparent background for full screen
};

const accessDeniedBoxStyle = {
  padding: '40px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark transparent box for the message
  borderRadius: '15px', // Slightly more rounded for better look
  color: '#fff', // White text color
  maxWidth: '450px', // Adjusted max-width to make it look better
  textAlign: 'center',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Slight shadow for better contrast
};

const backButtonStyle = {
  padding: '12px 25px',
  backgroundColor: '#5c67ff', // A nice color gradient
  color: '#fff',
  border: 'none',
  borderRadius: '10px',
  cursor: 'pointer',
  fontSize: '16px',
  marginTop: '15px', // Add some margin on top for spacing
  background: 'linear-gradient(135deg, #5c67ff, #8752ff)', // Gradient for a better visual
};

export default CategoryPage;
