import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from 'firebase/auth'; // Import setPersistence and browserSessionPersistence
import { useAuthState } from 'react-firebase-hooks/auth'; // Hook to check auth state
import { auth } from './firebaseConfig'; // Firebase auth instance
import { useNavigate, Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  // Check if user is already logged in
  const [user] = useAuthState(auth);

  // Load remembered email from localStorage (if present) when component mounts
  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true); // Check the "Remember Me" checkbox if email was stored
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleLogin = async () => {
    try {
      // Set session persistence (user will be logged out when browser is closed)
      await setPersistence(auth, browserSessionPersistence);

      // Attempt login with email and password
      await signInWithEmailAndPassword(auth, email, password);

      // If "Remember Me" is checked, save the email to localStorage
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }

      // Redirect to homepage after successful login
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  // Conditionally redirect in the render part, not inside hooks
  if (user) {
    navigate('/');
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="input-group">
          <i className="fas fa-user"></i>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <i className="fas fa-lock"></i>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* Remember Me checkbox */}
        <div className="options">
          <label>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)} // Toggle Remember Me
            />{' '}
            Remember me
          </label>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>

        <button onClick={handleLogin}>Login</button>

        <p>
          Don’t have an account? <Link to="/register">Sign Up</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
