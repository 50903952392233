import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Main from './main'; // Import the Main component
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute component
import CategoryPage from './categorypg'; // Import your CategoryPage component

function App() {
  return (
    <Router>
      <Routes>
        {/* Protected route for homepage (main content) */}
        <Route path="/" element={<ProtectedRoute><Main /></ProtectedRoute>} />
        
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Dynamic route for category pages */}
        <Route path="/category/:category" element={<ProtectedRoute><CategoryPage /></ProtectedRoute>} />
        
      </Routes>
    </Router>
  );
}

export default App;
