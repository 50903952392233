import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { sendPasswordResetEmail } from 'firebase/auth'; // Import Firebase method
import { auth } from './firebaseConfig'; // Import your Firebase auth configuration
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); 

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email); // Send reset email using Firebase
      alert('Password reset link sent!');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        <h2>Forgot Password</h2>
        <div className="input-group">
          <i className="fas fa-envelope"></i>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button onClick={handleResetPassword}>Reset Password</button>

        <button className="go-back-button" onClick={() => navigate('/login')}>
          Go Back to Login
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
