// src/firebaseConfig.js

// Import Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";



// Your Firebase configuration (replace with your actual Firebase project details)
const firebaseConfig = {
    apiKey: "AIzaSyBF08Cu5Xbs6VWDmz9smhrNKDj3iKRTkcY",
    authDomain: "course-platform-4a294.firebaseapp.com",
    projectId: "course-platform-4a294",
    storageBucket: "course-platform-4a294.appspot.com",
    messagingSenderId: "947865060356",
    appId: "1:947865060356:web:849c4e524078ca00056151",
    measurementId: "G-4B53Z3447K",
    databaseURL: "https://course-platform-4a294-default-rtdb.firebaseio.com"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app); // Initialize Realtime Database