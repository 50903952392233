import React, { useState } from 'react';
import './Login.css'; // Reuse the same CSS for consistency
import { createUserWithEmailAndPassword } from 'firebase/auth'; // Import Firebase signup function
import { auth, db } from './firebaseConfig'; // Import Firebase auth and Realtime Database instance
import { useNavigate } from 'react-router-dom'; // For redirecting after signup
import { ref, set } from 'firebase/database'; // Realtime Database methods

// Modal Component
function Modal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <p>{message}</p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}

function SignUp() {
  const [email, setEmail] = useState(''); // State to store the user's email
  const [password, setPassword] = useState(''); // State to store the user's password
  const [error, setError] = useState(''); // State to store any error messages
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [successMessage, setSuccessMessage] = useState(''); // State to store success message
  const navigate = useNavigate(); // For navigating after successful signup

  const handleSignUp = async () => {
    setLoading(true);
    setError(''); // Reset the error message

    try {
      // Sign up the user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Get the signed-up user

      // Store the user's email, isAdmin flag, and categories in Firebase Realtime Database
      await set(ref(db, 'users/' + user.uid), {
        email: user.email, // Save the user's email
        isAdmin: false, // All users registered as non-admins by default
        categories: {
          Category1: false, // Default access for categories
          Category2: false, 
          Category3: false, 
          Category4: false,
          Category5: false
        }
      });

      // Show modal with success message
      setSuccessMessage('Sign Up successful! 😃');
      setShowModal(true);
    } catch (error) {
      setError(error.message); // Display error if signup fails
    } finally {
      setLoading(false); // Stop the loading indicator after request finishes
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    navigate('/login'); // Redirect to login page after closing the modal
  };

  return (
    <div className="login-page"> {/* Use the same class for layout */}
      <div className="login-container"> {/* Same container */}
        <h2>Sign Up</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error if any */}
        <div className="input-group">
          <i className="fas fa-envelope"></i> {/* Use an appropriate icon for email */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state on input
          />
        </div>
        <div className="input-group">
          <i className="fas fa-lock"></i>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state on input
          />
        </div>
        <button onClick={handleSignUp} disabled={loading}>
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button> {/* Disable button while signing up */}
        <p>
          Already have an account? <a href="/login">Login</a> {/* Redirect to login */}
        </p>

        {/* Show the modal if signup is successful */}
        {showModal && <Modal message={successMessage} onClose={handleCloseModal} />}
      </div>
    </div>
  );
}

export default SignUp;
