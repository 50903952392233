import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Ensure Firebase is correctly initialized

function ProtectedRoute({ children }) {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>; // Show loading while checking authentication
  }

  if (!user) {
    // If not authenticated, redirect to login page
    return <Navigate to="/login" />;
  }

  return children; // If authenticated, return the main content
}

export default ProtectedRoute;
