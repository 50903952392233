import React, { useState } from 'react';
import backgroundImage from './homepageBackground.jpg'; // Import your background image
import { auth, db } from './firebaseConfig'; // Import Firebase auth and db
import { ref, get } from "firebase/database"; // Import ref and get from firebase/database
import {useNavigate } from 'react-router-dom'; // Import Link and useNavigate for navigation


function Main() {
  const [accessDeniedMessage, setAccessDeniedMessage] = useState(false); // State to control message box visibility
  const [deniedMessage, setDeniedMessage] = useState(''); // Custom message for access denial
  const navigate = useNavigate(); // React Router navigation

  const checkCategoryAccess = (categoryNumber, redirectTo) => {
    auth.onAuthStateChanged(user => {
      if (user) {
        const uid = user.uid;
        const categoryRef = ref(db, `users/${uid}/categories/Category${categoryNumber}`);

        // Check if the user has access to the category
        get(categoryRef).then(snapshot => {
          if (snapshot.exists() && snapshot.val()) {
            // User has access, navigate them to the category page
            navigate(`/category/${redirectTo}`); // Updated navigation to dynamic route
          } else {
            setDeniedMessage(`你没有权限访问 ${redirectTo.toUpperCase()} 😃`);
            setAccessDeniedMessage(true); // Show the message box on access denied
          }
        }).catch((error) => {
          console.error("Error fetching category access:", error);
        });
      } else {
        setDeniedMessage('请先登录再访问课程。');
        setAccessDeniedMessage(true); // Show the message box if not logged in
      }
    });
  };

  // Close the access denied message box
  const closeMessageBox = () => {
    setAccessDeniedMessage(false);
  };

  return (
    <div 
      style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        flexDirection: 'column', 
        height: '100vh', 
        width: '100vw', 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
        color: 'white', 
        textAlign: 'center', 
        overflow: 'hidden',
        position: 'relative' // Needed for positioning message box
      }}
    >
      {/* Text with background for readability */}
      <div style={{ 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '20px',
        borderRadius: '10px'
      }}>
        <h1>这里是主页</h1>
        <p>选择你的课程，开启知识的旅程！</p>
        <p>“学习的道路没有捷径，唯有坚持与努力。”</p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <div style={{ margin: '0 15px' }}>
          <button style={categoryButtonStyle} onClick={() => checkCategoryAccess(1, 'stab52')}>STAB52</button>
        </div>
        <div style={{ margin: '0 15px' }}>
          <button style={categoryButtonStyle} onClick={() => checkCategoryAccess(2, 'matb41')}>MATB41</button>
        </div>
        <div style={{ margin: '0 15px' }}>
          <button style={categoryButtonStyle} onClick={() => checkCategoryAccess(3, 'matb44')}>MATB44</button>
        </div>
        <div style={{ margin: '0 15px' }}>
          <button style={categoryButtonStyle} onClick={() => checkCategoryAccess(4, 'actb40')}>ACTB40</button>
        </div>
        <div style={{ margin: '0 15px' }}>
          <button style={categoryButtonStyle} onClick={() => checkCategoryAccess(5, 'mgeb02')}>MGEB02</button>
        </div>
      </div>



      {/* Access Denied Message Box */}
      {accessDeniedMessage && (
        <div style={messageBoxStyle}>
          <div style={messageContentStyle}>
            <p>{deniedMessage}</p>
            <button onClick={closeMessageBox} style={closeButtonStyle}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

const categoryButtonStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Light transparent background
  border: '1px solid rgba(255, 255, 255, 0.3)', // Subtle border for definition
  color: 'white', // Text color
  padding: '10px 20px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  cursor: 'pointer',
  borderRadius: '8px',
  backdropFilter: 'blur(5px)', // Glass-like blur effect
  transition: 'background-color 0.3s ease', // Smooth hover transition
};


const messageBoxStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background overlay
  zIndex: '1000' // Ensure it's on top of other elements
};

const messageContentStyle = {
  backgroundColor: '#333', // Change to a dark background
  color: 'white', // Keep the text color white
  padding: '20px',
  borderRadius: '10px',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

const closeButtonStyle = {
  marginTop: '10px',
  padding: '10px 20px',
  backgroundColor: '#ff0000',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer'
};

export default Main;

